<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div v-show="isWorkloadSelected">
    <workload-control-compose
      v-if="isPageReady && workloadModel.type === 'docker-compose' && isNewComposeSupported"
      :node-model="nodeModel"
      :workload-model="workloadModel"
      :permissions="permissions"
      :codesys-control-enabled="false"
      :update-btn-enabling="updateBtnEnabling"
      :is-connect-btn-enabled="doesRcExistForDeployedWlVersion"
      :is-snapshot-btn-disabled="false"
      :is-local-ui="false"
      :remote-connections="rc"
      :network-params="params"
      :error-message-network="errorMessageNetwork"
      :network-store-module="'workload-deploy-update'"
      :custom-fetch-action-network="'fetch_networks'"
      :custom-count-getter-network="'countNetworks'"
      :custom-list-getter-network="'networks'"
      :vm-status="workload.currentStatus"
      :can-access-logs="canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS')
        && (node.connectionStatus === 'online')"
      @undeploy-btn-clicked="undeploy"
      @workload-command="workloadCommand"
      @go-back="back()"
      @update-deployed-workload="updateDeployedWorkload"
      @connecting-btn-clicked="connectingBtnClicked"
      @tab-changed="tabChangedCompose"
      @download-inspect-command="service => downloadInspectCommand(service)"
    />
    <workload-control
      v-if="isPageReady && workloadModel.type !== 'docker-compose' || !isNewComposeSupported"
      :node-model="nodeModel"
      :workload-model="workloadModel"
      :permissions="permissions"
      :codesys-control-enabled="false"
      :update-btn-enabling="updateBtnEnabling"
      :is-connect-btn-enabled="doesRcExistForDeployedWlVersion"
      :is-snapshot-btn-disabled="false"
      :is-local-ui="false"
      :remote-connections="rc"
      :network-params="params"
      :error-message-network="errorMessageNetwork"
      :error-message-vm-backup="errorMessageVmBackup"
      :network-store-module="'workload-deploy-update'"
      :custom-fetch-action-network="'fetch_networks'"
      :custom-count-getter-network="'countNetworks'"
      :custom-list-getter-network="'networks'"
      :vm-status="workload.currentStatus"
      :backup-store-module="'vm-backups'"
      :backup-params="backupParams"
      :backup-model="backupModel"
      :is-backup-disabled="isBackupDisabled"
      :is-backup-add-disabled="isBackupAddDisabled"
      :snapshot-params="backupParams"
      :snapshot-store-module="'vm-snapshot'"
      :schedule="schedule"
      @save-schedule="saveSchedule"
      @delete-schedule="deleteSchedule"
      @restore-snapshot="restoreSnapshot"
      @save-snapshot="saveSnapshot"
      @create-vm-backup="createBackupWarning"
      @update-vm-resource-allocation="saveChanges"
      @undeploy-btn-clicked="undeploy"
      @open-apply-configuration-dialog="openApplyConfigurationDialog"
      @workload-command="workloadCommand"
      @go-back="back()"
      @update-deployed-workload="updateDeployedWorkload"
      @snapshot-btn-clicked="goToSnapshotPage"
      @connecting-btn-clicked="connectingBtnClicked"
      @tab-changed="tabChanged"
    />
    <update-deployed-workload-dialog
      v-if="workload.workloadId && workload.type !== 'vm'"
      :workload-id="workload.workloadId"
      :serial-number="node.serialNumber"
      :version="workload.version"
      @go-log="$emit('go-log')"
    />
    <dialog-for-establish-connection
      :name="rcName"
    />
  </div>
</template>

<script>
import { WorkloadControl, WorkloadControlCompose } from 'nerve-ui-components';
import UpdateDeployedWorkloadDialog from '@/components/workloads/updateDeployedWorkloadDialog.vue';
import mqtt from '@/plugins/mqtt';
import dialogForEstablishConnection from '@/components/remote-connection/dialogForEstablishConnection.vue';
import WorkloadsHelper from '@/store/helpers/workloads.helper';
import {
  STATUSES,
  CONTROLS,
  COMMANDS,
  BACKUP_STATUSES,
} from '@/model/node/sub-models/deployed-workload.model';
import Logger from '@/utils/logger';
import { BackupsApiService } from '../../../services/api';
import shared from '../../../helpers/shared';

export default {
  components: {
    WorkloadControl,
    WorkloadControlCompose,
    UpdateDeployedWorkloadDialog,
    dialogForEstablishConnection,
  },

  props: {
    versionId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      CONTROLS,
      STATUSES,
      COMMANDS,
      isWorkloadSelected: false, // wait for new selected workload
      isPageReady: false,
      rcName: '',
      isBackupDisabled: true,
      vmStatus: '',
      errorMessageVmBackup: '',
    };
  },
  computed: {
    errorMessageNetwork() {
      return this.$t(
        this.$store.getters['workload-deploy-update/errorMessageNetwork'],
      );
    },
    params() {
      return {
        serialNumber: this.node.serialNumber,
        deviceId: this.workload.id,
        status: this.workload.currentStatus,
      };
    },
    permissions() {
      return {
        uiWorkloadConfigurationApply: this.canAccess(
          'UI_WORKLOAD_CONFIGURATION:APPLY',
        ),
        uiWorkloadControlControl: this.canAccess('UI_WORKLOAD_CONTROL:CONTROL'),
        uiRemoteConnConnect: this.canAccess('UI_REMOTE_CONN:CONNECT'),
        uiWorkloadNetworkInterfaceDetails: this.canAccess('UI_WORKLOAD:NETWORK_INTERFACE_DETAILS'),
        uiWorkloadSnapshot: this.isSnapshotBtnVisible,
        uiWorkloadVmBackup: this.isBackupBtnVisible,
      };
    },
    nodeModel() {
      return {
        name: this.node.name,
        serialNumber: this.node.serialNumber,
        id: this.node.id,
        version: this.node.currentFWVersion,
      };
    },
    backupParams() {
      return {
        deviceId: this.workload.id,
        serialNumber: this.node.serialNumber,
      };
    },
    backupModel() {
      return {
        nodeSerialNumber: this.node.serialNumber,
        vmName: this.workload.name,
      };
    },
    workloadModel() {
      return {
        id: parseInt(this.workload.id, 10),
        name: this.workload.name,
        type: this.workload.type,
        version: this.workload.version,
        currentStatus: this.workload.currentStatus,
        workloadId: this.workload.workloadId,
        versionId: this.workload.versionId,
        configurationUpdateInfo: this.workload.configurationUpdateInfo,
        timestamp: this.workload.timestamp,
        stats: {
          ram:
            this.workload.stats && this.workload.stats.ram
              ? this.workload.stats.ram
              : 0,
          cpu:
            this.workload.stats && this.workload.stats.cpu
              ? this.workload.stats.cpu
              : 0,
        },
        resources: {
          cpu: this.workload.resources ? this.workload.resources.cpu : 0,
          memory: this.workload.resources ? this.workload.resources.memory : 0,
        },
        configurationUpdateStatus: {
          ...this.workload.configurationUpdateStatus,
        },
        versionsAvailableForWorkload: this.getVersionsOfWorkload,
        ...(this.workload.environmentVariables && {
          environmentVariables:
          this.workload.environmentVariables,
        }),
        ...(this.workload.portList && { ports: this.workload.portList }),
        ...(this.workload.services && { services: this.workload.services }),
      };
    },
    updateBtnEnabling() {
      return {
        numberOfVersionsAvailable: this.getVersionsOfWorkload.length,
        enabled:
          this.getVersionsOfWorkload && this.getVersionsOfWorkload.length !== 0
          && STATUSES[this.workload.currentStatus.toUpperCase()] !== STATUSES.REMOVING
          && STATUSES[this.workload.currentStatus.toUpperCase()] !== STATUSES.REMOVING_FAILED,
      };
    },
    doesRcExistForDeployedWlVersion() {
      if (this.getVersionsOfWorkload.length > 0) {
        return (this.getVersionsOfWorkload
          .find((element) => element.id === this.getVersionIdOfSelectedWl))
          ?.remoteConnections.length > 0;
      }
      return false;
    },
    node() {
      return this.$store.getters['node-tree/getSelectedNode'];
    },
    workload() {
      return this.$store.getters['node-tree/getSelectedDeployedWorkload'];
    },
    getVersionsOfWorkload() {
      return this.$store.getters['workload-deploy-update/getVersions'];
    },

    getWorkloadVersionRc() {
      return this.$store.getters['remote-connection/getWlRc'];
    },

    getVersionIdOfSelectedWl() {
      return this.$store.getters['node-tree/getVersionIdOfSelectedWl'];
    },

    rc() {
      return this.$store.getters['remote-connection/list'];
    },
    isWorkloadSnapshotFeatureEnabled() {
      const deployedWorkload = this.workload;
      if (!deployedWorkload || !deployedWorkload.features || !deployedWorkload.features.length) {
        return false;
      }
      const snapshot = deployedWorkload.features.find((feature) => feature.name === 'snapshot');
      if (!snapshot || !snapshot.value) {
        return false;
      }
      return !!snapshot.value.enabled;
    },

    isSnapshotBtnVisible() {
      return this.canAccess('UI_WORKLOAD:SNAPSHOT') && this.isWorkloadSnapshotFeatureEnabled && this.node.vmSnapshot;
    },

    isBackupBtnVisible() {
      return this.canAccess('UI_WORKLOAD:BACKUP') && this.node.vmBackup;
    },

    getBackups() {
      return this.$store.getters['vm-backups/list'];
    },
    isBackupAddDisabled() {
      return this.getBackups
        .some((backup) => backup.status !== BACKUP_STATUSES.COMPLETED
        && backup.status !== BACKUP_STATUSES.FAILED);
    },
    schedule() {
      return this.$store.getters['vm-snapshot/schedule']();
    },
    isNewComposeSupported() {
      return this.$store.getters['node-tree/getIsNewComposeSupported'];
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'node.connectionStatus': async function (status) {
      if (status === 'online') {
        WorkloadsHelper.clearExchangeInterval();
        await this.invokeMqttEmittingEvents();
      }
    },
    // eslint-disable-next-line func-names
    'node.currentFWVersion': function () {
      if (this.node && this.node.currentFWVersion) {
        const doesSatisfy = shared.isNoPrerelesedVersionGreaterThanOrEqualTo(this.node.currentFWVersion, '2.7.0');
        this.$store.dispatch('node-tree/set_is_new_compose_supported', doesSatisfy);
      }
    },
    // eslint-disable-next-line func-names
    'workload.currentStatus': function (status) {
      this.vmStatus = status;
    },
    // eslint-disable-next-line func-names
    'workload.services': function () {
      if (this.isNewComposeSupported) {
        this.workloadModel.services = this.$store.getters['node-tree/getSelectedDeployedWorkload'].services;
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      const deployedWorkloadId = window.location.pathname
        .split('/')
        .reverse()[0];
      try {
        await this.$store.dispatch(
          'node-tree/select_deployed_workload',
          deployedWorkloadId,
        );
      } catch (e) {
        Logger.error(e);
      } finally {
        if (this.canAccess('UI_NODE_TREE:NODE_DETAILS')) {
          await this.$store.dispatch('node-tree/restart_mqtt_events', this.node);
        }
        this.isWorkloadSelected = true;
        if (!this.workload.workloadId) {
          this.$router.push({
            name: 'Nodes',
            query: {
              tab: 0,
            },
          }).catch(() => {});
          // eslint-disable-next-line no-unsafe-finally
          return;
        }
        await this.$store.dispatch('workload-deploy-update/fetch', this.workload.workloadId);
        this.isPageReady = true;
        await this.$store.dispatch('remote-connection/getWorkloadVersionRc', { workloadId: this.workload.workloadId, versionId: this.getVersionIdOfSelectedWl });
        this.$store.dispatch('remote-connection/fetch', { remoteConnections: this.getWorkloadVersionRc });
        await this.invokeMqttEmittingEvents();
        if (this.workload.type === 'vm') {
          if (this.isBackupBtnVisible) {
            this.goToBackupsPage();
          }
          this.$store.dispatch('vm-backups/set_device_id', this.workload.id);
          this.$store.dispatch('vm-backups/set_serial_number', this.node.serialNumber);
          this.$store.dispatch('vm-snapshot/set_device_id', this.workload.id);
          this.$store.dispatch('vm-snapshot/set_serial_number', this.node.serialNumber);
        }
      }
    });
  },

  async created() {
    await mqtt.subscribeTo('backups', { serialNumber: this.node.serialNumber });
    await mqtt.subscribeTo('snapshot', { serialNumber: this.node.serialNumber });
  },

  beforeDestroy() {
    WorkloadsHelper.clearExchangeInterval();
    mqtt.unsubscribeFrom('backups', { serialNumber: this.node.serialNumber });
    mqtt.unsubscribeFrom('snapshot', { serialNumber: this.node.serialNumber });
  },
  methods: {
    async workloadCommand(data) {
      try {
        const { sessionId } = JSON.parse(localStorage.getItem('session'));
        data.params.sessionToken = sessionId;
        await this.$store.dispatch('node-tree/set_command', data);
      } catch (e) {
        Logger.error(e);
      }
    },
    openApplyConfigurationDialog() {
      this.$store.dispatch(
        'utils/_api_request_handler/show_apply_workload_configuration_dialog',
        {
          title: this.workload.name,
        },
      );
    },
    async undeploy() {
      const { sessionId } = JSON.parse(localStorage.getItem('session'));
      const params = {
        command: COMMANDS.UNDEPLOY,
        serialNumber: this.node.serialNumber,
        timeout: 0,
        forceStop: false,
        deviceId: this.workload.id,
        sessionToken: sessionId,
      };
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: 'nodes.deployedWorkloadControl.undeployWorkload',
        subTitle: 'nodes.deployedWorkloadControl.confirmTitle',
        callback: async () => {
          try {
            const command = CONTROLS.find((c) => c.command === COMMANDS.UNDEPLOY);
            // not waiting this action to finish
            WorkloadsHelper.clearExchangeInterval();
            this.$store.dispatch('node-tree/set_command', {
              params,
              command,
            });
            this.back();
          } catch (e) {
            Logger.error(e);
          }
        },
      });
    },
    async saveChanges(data) {
      try {
        data.serialNumber = this.node.serialNumber;
        data.device_id = data.deviceId;
        delete data.deviceId;
        if (this.workload.currentStatus === 'stopped') {
          this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
            title: 'workloadManagement.updateConfirmationStatusStoppedTitle',
            subTitle:
            'workloadManagement.updateConfirmationStatusStoppedSubtitle',
            callback: async () => {
              await this.$store.dispatch('node-tree/update_resources', data);
            },
          });
          return;
        }
        this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
          title: 'workloadManagement.updateConfirmationStatusRunningTitle',
          subTitle: 'workloadManagement.updateConfirmationStatusRunningSubtitle',
          callback: async () => {
            await this.$store.dispatch('node-tree/update_resources', data);
          },
        });
      } catch (e) {
        Logger.error(e);
      }
    },
    updateDeployedWorkload() {
      this.$store.dispatch(
        'workload-deploy-update/open_update_workload_dialog',
      );
    },
    async goToBackupsPage() {
      try {
        const response = await BackupsApiService
          .isLocalRepositoryConfigured(this.node.serialNumber);
        if (response) {
          this.isBackupDisabled = false;
        }
        // Even when local repository is not configured user
        // can still navigate to the page with backup logs
        // Instead of backup log history appropriate message will be displayed.
        if (!response.isConfigured) {
          this.errorMessageVmBackup = this.$t('workloadManagement.localRepositoryNotConfiguredMessageText');
          return;
        }
      } catch (e) {
        Logger.error(e);
      }
    },
    back() {
      WorkloadsHelper.clearExchangeInterval();
      this.$router.go(-1);
    },

    goToSnapshotPage() {
      this.$router.push({
        name: 'Snapshots',
        params: {
          currentGtwId: this.node.id,
          id: this.workloadModel.id,
        },
      });
    },
    async connectingBtnClicked(item) {
      this.rcName = item.name;
      this.$store.dispatch('remote-connection/openCloseConnectingDialog', true);
      const rc = {
        acknowledgment: item.acknowledgment,
        connection: item.connection,
        name: item.name,
        port: item.port,
        serialNumber: this.node && this.node.serialNumber
          ? this.node.serialNumber : this.nodeSerial,
        type: item.type,
        versionId: this.workload.versionId || '',
        workloadId: this.workload.workloadId || '',
        _id: item._id,
      };
      try {
        // Subscribing to the mqtt-topic prior to sending the API request - NERVESW-9868
        await mqtt.subscribeTo('initRc');
        await this.$store.dispatch('remote-connection/remoteConnect', rc);
      } catch (err) {
        this.$store.dispatch('remote-connection/openCloseConnectingDialog', false);
      }
    },

    async tabChanged(workloadModelId) {
      await this.$store.dispatch(
        'node-tree/select_deployed_workload',
        workloadModelId,
      );
    },

    async tabChangedCompose({ workloadId, tab }) {
      WorkloadsHelper.clearExchangeInterval();
      await this.$store.dispatch(
        'node-tree/select_deployed_workload',
        workloadId,
      );
      WorkloadsHelper.setSelectedWorkloadTab(tab);
      await this.invokeMqttEmittingEvents();
    },

    async createBackup(data) {
      try {
        await this.$store.dispatch('vm-backups/create_backup', {
          reqObj: {
            name: data.name,
          },
          serialNumber: this.node.serialNumber,
          deviceId: this.params.deviceId,
        });
      } catch (e) {
        Logger.error(e);
      }
    },
    async createBackupWarning(data) {
      if (this.workload.currentStatus === 'stopped') {
        await this.createBackup(data);
        return;
      }
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: this.$t('workloadManagement.vmBackupCreationTile'),
        subTitle: this.$t('workloadManagement.vmBackupCreationConfirmationMsg', { name: this.workload.name || '' }),
        callback: async () => this.createBackup(data),
      });
    },
    async saveSnapshot(snapshot) {
      try {
        this.$store.dispatch('utils/_api_request_handler/show_loading_bar');
        await this.$store.dispatch('vm-snapshot/create', { snapshot, deviceId: this.workload.id, serialNumber: this.node.serialNumber });
      } catch (e) {
        this.$store.dispatch('utils/_api_request_handler/close_loading_bar');
      }
    },
    async restoreSnapshot(snapshot) {
      try {
        this.$store.dispatch('utils/_api_request_handler/show_loading_bar');
        await this.$store.dispatch('vm-snapshot/restore', { snapshot, deviceId: this.workload.id, serialNumber: this.node.serialNumber });
      } catch (e) {
        this.$store.dispatch('utils/_api_request_handler/close_loading_bar');
      }
    },
    async saveSchedule(schedule) {
      try {
        this.$store.dispatch('utils/_api_request_handler/show_loading_bar');
        await this.$store.dispatch('vm-snapshot/schedule', { schedule, deviceId: this.workload.id, serialNumber: this.node.serialNumber });
        this.$store.dispatch('utils/_api_request_handler/close_loading_bar');
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'vmSnapshot.saveScheduleSnapshotMessage',
          color: 'success',
          showClose: true,
        });
      } catch (e) {
        this.$store.dispatch('utils/_api_request_handler/close_loading_bar');
      }
    },
    async deleteSchedule() {
      try {
        this.$store.dispatch('utils/_api_request_handler/show_loading_bar');
        await this.$store.dispatch('vm-snapshot/remove_schedule', { deviceId: this.workload.id, serialNumber: this.node.serialNumber });
        this.$store.dispatch('utils/_api_request_handler/close_loading_bar');
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'vmSnapshot.deleteScheduleSnapshotMessage',
          color: 'success',
          showClose: true,
        });
      } catch (e) {
        this.$store.dispatch('utils/_api_request_handler/close_loading_bar');
      }
    },
    async invokeMqttEmittingEvents() {
      const isVersionSatisfying = shared.isNoPrerelesedVersionGreaterThanOrEqualTo(this.node.currentFWVersion, '2.5.0');
      if (isVersionSatisfying && this.workload.type !== 'codesys') {
        try {
          if (this.canAccess('UI_NODE_TREE:NODE_DETAILS')) {
            await WorkloadsHelper.invokeMqttEvents();
          }
        } catch (e) {
          Logger.error('NodesApiService.keepEmittingUpdates ', e.message);
          WorkloadsHelper.clearExchangeInterval();
          mqtt.unsubscribeFrom('node', this.node);
        }
      }
    },
    async downloadInspectCommand(service) {
      const result = await this.$store.dispatch(
        'node-tree/get_docker_inspect_result',
        {
          dataId: `${this.workloadModel.workloadId}_${this.workloadModel.versionId}_service_docker_inspect`,
          serialNumber: this.node.serialNumber,
          data: {
            containerName: service.containerName,
          },
        },
      );
      const value = result.values;
      // A function that alters the behavior of the "stringification" process, or array.
      // If replacer is an array, all elements in this array that are not strings or numbers,
      // including Symbol values, are completely ignored. If replacer is null
      // all string-keyed properties of the object are included in the resulting JSON string.
      const replacer = null;
      // A string or number that's used to insert white space into the output
      // JSON string for readability purposes.
      const space = 4;
      const url = window.URL.createObjectURL(new Blob([JSON.stringify(value, replacer, space)], {
        type: 'application/json',
      }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${service.serviceName}_inspect_output.json`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
